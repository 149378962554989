import TYPES from '@/types';

import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';

// Domain
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

export default class FlagshipProfilingStepIntroductionViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_invest_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship-profiling-step-introduction';

  private retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
    id: v4(),
    issued_age: 0,
    retirement_age_adjusted: 0,
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
    },
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.retirement_investor_goal_entity.investor_goal.investor_profile_id = investor_profile.id;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  createRetirementGoal = async () => {
    try {
      this.view.$emit('loadingStep', true);
      await this.create_retirement_invest_command.execute(this.retirement_investor_goal_entity);
    } catch (error) {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.create_retirement_goal'),
      );
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  createRetirementGoalIfNotExists = async () => {
    try {
      this.view.$emit('loadingStep', true);
      await this.get_retirement_investor_goal_query.execute();
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.messageNotifier.showErrorNotification(
          this.translate('errors.load_retirement_goal'),
        );
      }
      if (JSON.parse(error).status_code === 404) {
        await this.createRetirementGoal();
      }
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  initialize = async () => {
    await this.loadInvestorProfile();
    await this.createRetirementGoalIfNotExists();
  }
}
