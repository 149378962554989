
















































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepIntroductionViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-introduction-view-model';

@Component({ name: 'FlagshipProfilingStepIntroduction' })
export default class FlagshipProfilingStepIntroduction extends Vue {
  flagship_introduction_view_model = Vue.observable(
    new FlagshipProfilingStepIntroductionViewModel(this),
  );

  created() {
    this.flagship_introduction_view_model.initialize();
  }
}
